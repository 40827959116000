<template>
  <div>
    <v-navigation-drawer
        class="non-printable"
        app
        :width="navWidth"
        right
        v-model="setting_dialog"
        temporary
        @input="onNavInput"
    >
      <v-btn
          @click="closeSettings"
          depressed
      >Приховати налаштування
      </v-btn>

      <v-row>
        <v-col cols="12">
          <v-card tile elevation="0">
            <v-card-text class="pt-2">
              <v-subheader class="pl-1">Налаштування</v-subheader>
              <v-row class="dense-wrapper">
                <v-col cols="6">
                  <v-switch
                      v-model="filters.show_owner"
                      inset
                      hide-details
                      color="success"
                      class="mt-0 ml-2 mr-4"
                      :label="filters.show_owner ? 'Виводити власника' : 'Не виводити власника'"
                  />
                </v-col>
                <v-col cols="6">
                  <v-switch
                      v-model="filters.show_encrypted_owner"
                      inset
                      hide-details
                      color="success"
                      class="mt-0 ml-2 mr-4"
                      :label="filters.show_encrypted_owner ? 'Приховати прізвище власника' : 'Не приховувати прізвище власника'"
                  />
                </v-col>
                <v-col cols="6">
                  <v-switch
                      v-model="filters.show_square"
                      inset
                      hide-details
                      color="success"
                      class="mt-0 ml-2"
                      :label="filters.show_square ? 'Виводити площу' : 'Не виводити площу'"
                  />
                </v-col>
                <v-col cols="6">
                  <v-switch
                      v-model="filters.bank_account_by_row"
                      inset
                      hide-details
                      color="success"
                      class="mt-0 ml-2 mr-4"
                      :label="filters.bank_account_by_row ?
                      'Виводити банківські реквізити на кожну послугу'
                      : 'Не виводити банківські реквізити на кожну послугу'"
                  />
                </v-col>
                <v-col cols="12">
                  <v-switch
                      v-model="filters.custom_title"
                      inset
                      hide-details
                      color="success"
                      class="mt-0 ml-2 mr-4"
                      :label="filters.custom_title ? 'Довільний заголовок' : 'Стандартний заголовок'"
                  />
                </v-col>
                <v-col cols="12" class="d-flex">
                  <v-text-field filled
                                :disabled="!filters.custom_title"
                                label="Заголовок"
                                hide-details
                                color="grey"
                                v-model="filters.title"/>
                </v-col>
                <v-col cols="12" class="d-flex">
                  <v-text-field filled
                                label="Додатковий текст"
                                hide-details
                                color="grey"
                                v-model="filters.additional_text"/>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text class="pt-2">
              <v-subheader class="pl-1">Відбір</v-subheader>
              <v-row class="dense-wrapper">
                <v-col cols="12" v-if="without_flat">
                  <AddressElementSelect v-model="filters.city"
                                        label="Населений пункт" filled
                                        select_type="city"
                  />
                </v-col>
                <v-col cols="12" v-if="without_flat">
                  <AddressElementSelect :parent_id="filters.city" v-model="filters.streets"
                                        label="Вулиця(-ки)" multiple filled
                                        select_type="street" use_parent_id
                                        :disabled="!filters.city"
                  />
                </v-col>
                <v-col cols="12" v-if="without_flat">
                  <AddressElementSelect :parent_id="filters.streets" v-model="filters.buildings"
                                        label="Будинок(-ки)" multiple filled
                                        select_type="building" use_parent_id
                                        :disabled="!filters.streets.length"
                  />
                </v-col>
              </v-row>
              <v-row class="mt-2 dense-wrapper">
                <v-col cols="12" md="12">
                  <v-switch
                      v-model="filters.use_end_balance"
                      inset
                      hide-details
                      color="success"
                      class="mt-0 ml-2"
                      :label="filters.use_end_balance ? 'Відбір по кін. сальдо (включений)' : 'Відбір по кін.сальдо (виключений)'"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-decimal filled :disabled="!filters.use_end_balance" label="Сальдо кін. від" hide-details v-model.number="filters.end_balance_start"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-decimal filled :disabled="!filters.use_end_balance" label="Сальдо кін. до" hide-details v-model.number="filters.end_balance_end"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select multiple filled :items="services" hide-details v-model="filters.only_services" color="grey" label="Абоненти із цими послугами"/>
                </v-col>
                <v-col cols="12">
                  <v-select multiple filled :items="services" hide-details v-model="filters.exclude_services" color="grey" label="Абоненти без цих послуг"/>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="filters.postal_index" hide-details filled color="grey" clearable
                                label="Поштовий індекс" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <div class="pages" id="print">
      <div class="page page-a4 page-separator page-for-print" v-for="(page, index) in pages" :key="`page-${index}`" style="margin-bottom: 2px;">
        <div class="report-section" style="flex-wrap: wrap">
          <div class="rp-col-12 receipt" v-for="(col, idx) in items.filter(item => item.page === page)" :key="`col-idx-${idx}`" >
            <div class="receipt-content" style="font-size: 11px; padding-top: 2px">
              <div class="receipt-row" style="font-weight: 500; align-items: center; position: relative; height: 21px">
                <div style="flex: 1; font-size: 12px; text-transform: uppercase; border-right: 1px dashed grey; padding-right: 6px;">
                  {{ col.title }}
                </div>
                <div style="flex: 0 0 74px; position: relative; border-top: 1px dashed grey; height: 21px; border-right: 1px dashed grey">
                  <img v-if="col.qr_url"
                       :src="`${BASE_URL_AXIOS}/${col.qr_url}`"
                       alt="qr code"
                       style="height: 64px; width: 64px; object-fit: fill; position: absolute; left: 5px; top: 2px">
                  <template v-else>
                    <img v-if="organization.qr_url"
                         :src="`${BASE_URL_AXIOS}/${organization.qr_url}`"
                         alt="qr code"
                         style="height: 64px; width: 64px; object-fit: fill; position: absolute; left: 5px; top: 2px">
                  </template>
                </div>
              </div>
              <div class="receipt-row" style="display: flex; flex-wrap: nowrap; height: 24px; overflow: hidden; line-height: 1.1">
                <div style="flex: 1; border-right: 1px dashed grey; padding-right: 6px; font-size: .7rem">
                  {{ print_data.organization_details_with_account }}
                </div>
                <div style="flex: 0 0 74px; border-right: 1px dashed grey">

                </div>
              </div>
              <div class="receipt-row">
                <div style="flex: 1; text-align: left">
                  <span style="text-transform: uppercase">Код особистого кабінету:</span> <span
                    style="font-weight: bold; font-size: 14px">{{ col.person_hash }}</span>
                </div>
                <div style="flex: 1; text-align: right; border-right: 1px dashed grey; padding-right: 4px">
                  <span style="text-transform: uppercase">Особовий рахунок:</span> <span
                    style="font-weight: bold; font-size: 14px">{{ col.personal_id }}</span>
                </div>
                <div style="flex: 0 0 74px; border-right: 1px dashed grey">

                </div>
              </div>
              <div class="receipt-row"
                   style="align-items: center; font-weight: 500; padding-top: 4px; margin-top: 2px; border-top: 2px solid #5e5e5e; line-height: 1.1; font-size: 13px">
                <div style="flex: 1">
                  Адреса: <span>{{ col.address }}</span>
                </div>
              </div>
              <div class="receipt-row" style="line-height: 1.1; padding-top: 2px">
                {{ col.owner_square_row }}
              </div>
              <div class="empty-row" style="height: 3px"></div>
              <div class="receipt-row">
                <div class="report-table">
                  <div class="report-table-row">
                    <div class="report-table-col" style="flex: 0 0 170px">
                      <span>Послуга</span>
                    </div>
                    <div class="report-table-col" style="flex: 0 0 60px">
                      <span>Об’єм</span>
                    </div>
                    <div class="report-table-col" style="flex: 0 0 60px">
                      <span>Тариф</span>
                    </div>
                    <div class="report-table-col" style="flex: 0 0 73px;">
                      <span>Сальдо на <br> {{date_start | formatDate}}</span>
                    </div>
                    <div class="report-table-col" style="flex: 0 0 74px;">
                      <span>Нараховано</span>
                    </div>
                    <div class="report-table-col" style="flex: 0 0 74px;">
                      <span>Перерах.</span>
                    </div>
                    <div class="report-table-col" style="flex: 0 0 73px;">
                      <span>Монетиз.</span>
                    </div>
                    <div class="report-table-col" style="flex: 0 0 73px;">
                      <span>Оплачено</span>
                    </div>
                    <div class="report-table-col" style="flex: 1;">
                      <span>Сальдо на <br> {{ date_end | formatDate }}</span>
                    </div>
                  </div>
                  <div class="report-table-row"
                       :class="{'union-rows': filters.bank_account_by_row}"
                       v-for="(row, row_idx) in col.items" :key="`page-${index}-${row_idx}`">
                    <template v-if="filters.bank_account_by_row">
                      <div class="left-col">
                          <span>{{ row.service_name }}</span>
                      </div>
                      <div class="right-col">
                        <div class="first-row">
                          <div class="report-table-col-1" style="flex: 0 0 60px">
                            <span>{{ row.volume | formatNumber }}</span>
                          </div>
                          <div class="report-table-col-1 union" style="flex: 0 0 60px">
                            <span>{{ row.tariff | formatNumber }}</span>
                          </div>
                          <div class="report-table-col-1" style="flex: 0 0 73px;">
                            <span>{{ row.start_balance | formatNumber }}</span>
                          </div>
                          <div class="report-table-col-1" style="flex: 0 0 74px;">
                            <span>{{ row.charge | formatNumber }}</span>
                          </div>
                          <div class="report-table-col-1" style="flex: 0 0 74px;">
                            <span>{{ row.recalculation | formatNumber }}</span>
                          </div>
                          <div class="report-table-col-1" style="flex: 0 0 73px;">
                            <span>{{ row.monetary | formatNumber }}</span>
                          </div>
                          <div class="report-table-col-1" style="flex: 0 0 73px;">
                            <span>{{ row.pay | formatNumber }}</span>
                          </div>
                          <div class="report-table-col-1" style="flex: 1;">
                            <span>{{ row.end_balance | formatNumber }}</span>
                          </div>
                        </div>
                        <div class="second-row">
                          <div class="report-table-col-1" style="flex: 1">
                            <span>
                              {{ `Для оплати: ${row.bank_account_account} в ${row.bank_account_name}` }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div class="report-table-col" style="flex: 0 0 170px">
                        <span>{{ row.service_name }}</span>
                      </div>
                      <div class="report-table-col" style="flex: 0 0 60px">
                        <span>{{ row.volume | formatNumber }}</span>
                      </div>
                      <div class="report-table-col union" style="flex: 0 0 60px">
                        <span>{{ row.tariff | formatNumber }}</span>
                      </div>
                      <div class="report-table-col" style="flex: 0 0 73px;">
                        <span>{{ row.start_balance | formatNumber }}</span>
                      </div>
                      <div class="report-table-col" style="flex: 0 0 74px;">
                        <span>{{ row.charge | formatNumber }}</span>
                      </div>
                      <div class="report-table-col" style="flex: 0 0 74px;">
                        <span>{{ row.recalculation | formatNumber }}</span>
                      </div>
                      <div class="report-table-col" style="flex: 0 0 73px;">
                        <span>{{ row.monetary | formatNumber }}</span>
                      </div>
                      <div class="report-table-col" style="flex: 0 0 73px;">
                        <span>{{ row.pay | formatNumber }}</span>
                      </div>
                      <div class="report-table-col" style="flex: 1;">
                        <span>{{ row.end_balance | formatNumber }}</span>
                      </div>
                    </template>
                  </div>
                  <div class="report-table-row" style="font-weight: bold">
                    <div class="report-table-col" style="flex: 0 0 290px">
                      <span>Всього: </span>
                    </div>
                    <div class="report-table-col" style="flex: 0 0 73px;">
                      <span>{{ col.start_balance | formatNumber }}</span>
                    </div>
                    <div class="report-table-col" style="flex: 0 0 74px;">
                      <span>{{ col.charge | formatNumber }}</span>
                    </div>
                    <div class="report-table-col" style="flex: 0 0 74px;">
                      <span>{{ col.recalculation | formatNumber }}</span>
                    </div>
                    <div class="report-table-col" style="flex: 0 0 73px;">
                      <span>{{ col.monetary | formatNumber }}</span>
                    </div>
                    <div class="report-table-col" style="flex: 0 0 73px;">
                      <span>{{ col.pay | formatNumber }}</span>
                    </div>
                    <div class="report-table-col" style="flex: 1;">
                      <span>{{ col.end_balance | formatNumber }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="empty-row" style="height: 1px"></div>
              <div class="receipt-row" style="align-items: center; height: 29px; overflow: hidden">
                <div style="flex: 0 0 140px; padding-top: 2px">
                  Квартирні лічильники
                </div>
                <div style="flex: 1; padding-top: 2px">
                  {{ col.flat_counters ? col.flat_counters : 'Дані по лічильниках відсутні' }}
                </div>
              </div>
              <div class="empty-row" style="border-bottom: 1px dashed grey;"></div>
              <div class="receipt-row" style="align-items: center; height: 29px; overflow: hidden">
                <div style="flex: 0 0 140px; padding-top: 2px">
                  Будинкові лічильники
                </div>
                <div style="flex: 1; padding-top: 2px">
                  {{ col.build_counters ? col.build_counters : 'Дані по об’ємах будинкових лічильників відсутні' }}
                </div>
              </div>
              <div class="empty-row" style="border-bottom: 1px dashed grey;"></div>
              <div class="empty-row" style="height: 3px"></div>
              <div class="receipt-row" style="align-items: flex-start; height: 34px; overflow: hidden; line-height: 1.1">
                <div style="flex: 1">
                  <span style="font-style: oblique">Шановні мешканці! Повідомляємо Вас, що розраховуватися слід до {{ pay_date | formatDate }} року.</span>
                  <span style="font-weight: 500"> Особистий кабінет: www.wetoo.vinksoft.com.ua</span>
                </div>
                <div style="flex: 0 0 300px; text-transform: uppercase; text-align: right; justify-content: flex-end; align-self: center">
                  <span style="font-size: 1.16rem;"> До сплати: </span><span style="font-weight: bolder; font-size: 1.22rem;">{{ (col.end_balance >= 0 ? col.end_balance : 0) | formatNumber }}</span><span
                    style="text-transform: lowercase; font-weight: bolder; font-size: 1rem"> грн.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="separator"></div>
      </div>
    </div>
  </div>
</template>

<script>

import {formatDate} from "@/filters";
import reportAPI from "@/utils/axios/reports";
import {mapActions, mapGetters} from "vuex";
import {SELECT_SERVICES_WITHOUT_ALL_SERVICE} from "@/store/actions/services";
import {ALERT_SHOW} from "@/store/actions/alert";
import {addMothsToStringDate, addDaysToStringDate, beginOfMonth} from "@/utils/icons"
import {BASE_URL_AXIOS} from "@/utils/axios";

export default {
  name: "receipt_detail",
  props: ['organization', 'print_data', 'global_settings', 'scale', 'date_start', 'date_end', 'generate', 'clear',
    'generate_xlsx', 'flat', 'generate_email', 'email_address', 'generate_send_by_one_email'],
  components: {
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
  },
  computed: {
    ...mapGetters(
        {
          services: 'getServicesSelectWithoutAll'
        }
    ),
    navWidth() {
      if (this.$vuetify.breakpoint.xs) {
        return '80%'
      }

      if (this.$vuetify.breakpoint.sm) {
        return '60%'
      }

      return '35%'
    },
    without_flat() {
      return !this.flat || Object.keys(this.flat || {}).length === 0
    }
  },
  data() {
    return {
      pages: 0,
      per_page: 0,
      items: [],
      setting_dialog: this.global_settings,
      current_date: this.getCurrentDate(),
      settings_watcher: null,
      generate_watcher: null,
      generate_xlsx_watcher: null,
      generate_email_watcher: null,
      generate_send_by_one_email_watcher: null,
      clear_watcher: null,
      local_response_people: this.print_data?.response_people ? this.print_data.response_people.slice() : [],
      pay_date: null,
      filters: {
        custom_title: false,
        title: 'Повідомлення про нарахування за {МісяцьЗвіту}',
        show_owner: true,
        show_square: true,
        show_encrypted_owner: false,
        additional_text: '',
        city: null,
        streets: [],
        buildings: [],
        only_services: [],
        exclude_services: [],
        use_end_balance: false,
        end_balance_start: 0,
        end_balance_end: 0,
        bank_account_by_row: false,
        postal_index: null
      },
      publicPath: process.env.BASE_URL,
      BASE_URL_AXIOS
    }
  },
  methods: {
    ...mapActions(
        {
          fetchServices: SELECT_SERVICES_WITHOUT_ALL_SERVICE
        }
    ),
    getBasePath() {
      return window.location.origin
    },
    onNavInput(e) {
      if (!e) {
        this.$emit('closeSettings')
      }
    },
    generate_xlsx_file() {
      if (this.date_start && this.date_end) {
        this.$emit('xlsx_loading_status', true)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          filters: {
            city: this.filters.city,
            streets: this.filters.streets,
            buildings: this.filters.buildings,
            only_services: this.filters.only_services,
            exclude_services: this.filters.exclude_services,
            use_end_balance: this.filters.use_end_balance,
            end_balance_start: this.filters.end_balance_start,
            end_balance_end: this.filters.end_balance_end,
            show_owner: this.filters.show_owner,
            show_encrypted_owner: this.filters.show_encrypted_owner,
            show_square: this.filters.show_square,
            show_building_counter: this.filters.show_building_counter,
            show_flat_counter: this.filters.show_flat_counter,
            custom_title: this.filters.custom_title,
            title: this.filters.title,
            additional_text: this.filters.additional_text,
            bank_account_by_row: this.filters.bank_account_by_row,
            postal_index: this.filters.postal_index
          }
        }
        if (this.flat) {
          payload.filters.flat = this.flat.id
        }
        payload.filters.user_url = this.getBasePath()

        reportAPI.receipt_detail_xlsx(payload)
            .then(response => {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a');
              const file_name = `receipt_detail_${formatDate(this.date_start, 'MM_YYYY')}_${formatDate(this.date_end, 'MM_YYYY')}.xlsx`
              link.href = url;
              link.setAttribute('download', file_name); //or any other extension
              document.body.appendChild(link);
              link.click();
              this.$emit('xlsx_loading_status', false)
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
              this.$emit('xlsx_loading_status', false)
            })
            .finally(() => {
              this.$emit('generate_xlsx')
            })
      }
    },
    generate_report() {
      if (this.date_start && this.date_end) {
        this.$emit('report_loading_status', true)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          filters: {
            city: this.filters.city,
            streets: this.filters.streets,
            buildings: this.filters.buildings,
            only_services: this.filters.only_services,
            exclude_services: this.filters.exclude_services,
            use_end_balance: this.filters.use_end_balance,
            end_balance_start: this.filters.end_balance_start,
            end_balance_end: this.filters.end_balance_end,
            show_owner: this.filters.show_owner,
            show_encrypted_owner: this.filters.show_encrypted_owner,
            show_square: this.filters.show_square,
            show_building_counter: this.filters.show_building_counter,
            show_flat_counter: this.filters.show_flat_counter,
            custom_title: this.filters.custom_title,
            title: this.filters.title,
            additional_text: this.filters.additional_text,
            bank_account_by_row: this.filters.bank_account_by_row,
            postal_index: this.filters.postal_index
          }
        }
        if (this.flat) {
          payload.filters.flat = this.flat.id
        }
        payload.filters.user_url = this.getBasePath()
        reportAPI.receipt_detail(payload)
            .then(response => response.data)
            .then(data => {
              this.pay_date = addMothsToStringDate(addDaysToStringDate(beginOfMonth(this.date_start), 19), 1)
              this.setSplitter(data)
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.expanded = []
              this.$emit('generate_report', !!this.items)
              this.$emit('report_loading_status', false)
            })
      }
    },
    generate_email_send() {
      if (this.date_start && this.date_end) {
        this.$emit('email_loading_status', true)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          filters: {
            city: this.filters.city,
            streets: this.filters.streets,
            buildings: this.filters.buildings,
            only_services: this.filters.only_services,
            exclude_services: this.filters.exclude_services,
            use_end_balance: this.filters.use_end_balance,
            end_balance_start: this.filters.end_balance_start,
            end_balance_end: this.filters.end_balance_end,
            show_owner: this.filters.show_owner,
            show_square: this.filters.show_square,
            show_encrypted_owner: this.filters.show_encrypted_owner,
            show_building_counter: this.filters.show_building_counter,
            show_flat_counter: this.filters.show_flat_counter,
            custom_title: this.filters.custom_title,
            title: this.filters.title,
            additional_text: this.filters.additional_text,
            bank_account_by_row: this.filters.bank_account_by_row,
            postal_index: this.filters.postal_index
          },
          email: this.email_address
        }
        if (this.flat) {
          payload.filters.flat = this.flat.id
        }
        payload.filters.user_url = this.getBasePath()
        reportAPI.receipt_detail_email(payload)
            .then(response => response.data)
            .then(data => {
              this.$emit('email_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {color: data.status === 'OK' ? 'success' : 'error', text: data.text})
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$emit('email_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_email')
            })
      }
    },
    generate_send_email_by_one_by_flat() {
      if (this.date_start && this.date_end) {
        this.$emit('email_by_one_loading_status', true)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          filters: {
            city: this.filters.city,
            streets: this.filters.streets,
            buildings: this.filters.buildings,
            only_services: this.filters.only_services,
            exclude_services: this.filters.exclude_services,
            use_end_balance: this.filters.use_end_balance,
            end_balance_start: this.filters.end_balance_start,
            end_balance_end: this.filters.end_balance_end,
            show_owner: this.filters.show_owner,
            show_square: this.filters.show_square,
            show_encrypted_owner: this.filters.show_encrypted_owner,
            show_building_counter: this.filters.show_building_counter,
            show_flat_counter: this.filters.show_flat_counter,
            custom_title: this.filters.custom_title,
            title: this.filters.title,
            additional_text: this.filters.additional_text,
            bank_account_by_row: this.filters.bank_account_by_row,
            postal_index: this.filters.postal_index
          }
        }
        payload.filters.user_url = this.getBasePath()
        reportAPI.receipt_detail_email_by_one(payload)
            .then(response => response.data)
            .then(data => {
              this.$emit('email_by_one_loading_status', false)
              if (data.error_text) {
                this.$store.dispatch(ALERT_SHOW, {color: 'error', text: data.error_text})
              } else {
                this.$store.dispatch(ALERT_SHOW,
                    {
                      color: 'success',
                      text: `Надсилання рахунків завершено. Успішно: ${data.success}. Помилково: ${data.error}`
                    }
                )
              }
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$emit('email_by_one_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_send_by_one_email')
            })
      }
    },
    setSplitter(payload) {
      let pages = 0
      let per_page = 62
      let counter = 0
      let by_receipt = 17
      let max_pages = 0
      const by_service_row = this.filters.bank_account_by_row ? 2 : 1

      payload.forEach(item => {
        item['flat_counters'] = ''
        item['build_counters'] = ''

        counter += by_receipt
        item.items.forEach(() => {
          counter += by_service_row
          if (counter >= per_page) {
            item['page'] = pages + 1
            pages += 1
            counter = 0
            counter += by_receipt
          } else {
            item['page'] = pages + 1
          }

          if (item['page'] > max_pages) {
            max_pages = item['page']
          }
        })
        item.counters.forEach(counter => {
          item.flat_counters += `${counter.abbreviation} (№${counter.serial_number}) показ. ${counter.current_meter_reading_first}; `
        })
        item.building_counters.forEach(counter => {
          item.build_counters += `${counter.abbreviation} (${counter.counter_name}) об’єм ${counter.result}; `
        })
      })

      if (payload.length > 0 && pages === 0) {
        pages = 1
      }

      if (max_pages > pages) {
        pages = max_pages
      }

      this.pages = pages
      this.per_page = per_page
      this.items = Object.freeze(payload)
    },
    formatDate,
    getCurrentDate() {
      const date = new Date()
      const year = date.getUTCFullYear()
      const month = date.getUTCMonth() + 1 < 10 ? '0' + (date.getUTCMonth() + 1) : date.getUTCMonth() + 1
      const day = date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate()
      return `${year}-${month}-${day}`
    },
    closeSettings() {
      this.setting_dialog = false
      this.$emit('closeSettings')
    },
    watch_settings() {
      this.settings_watcher = this.$watch(
          'global_settings',
          {
            handler(payload) {
              this.setting_dialog = payload
            }
          }
      )
    },
    watch_generate() {
      this.generate_watcher = this.$watch(
          'generate',
          {
            handler(payload) {
              if (payload) {
                this.generate_report()
              }
            }
          }
      )
    },
    watch_generate_xlsx() {
      this.generate_xlsx_watcher = this.$watch(
          'generate_xlsx',
          {
            handler(payload) {
              if (payload) {
                this.generate_xlsx_file()
              }
            }
          }
      )
    },
    watch_generate_email() {
      this.generate_email_watcher = this.$watch(
          'generate_email',
          {
            handler(payload) {
              if (payload) {
                this.generate_email_send()
              }
            }
          }
      )
    },
    watch_generate_send_by_one_email() {
      this.generate_send_by_one_email_watcher = this.$watch(
          'generate_send_by_one_email',
          {
            handler(payload) {
              if (payload) {
                this.generate_send_email_by_one_by_flat()
              }
            }
          }
      )
    },
    watch_clear() {
      this.clear_watcher = this.$watch(
          'clear',
          {
            handler(payload) {
              if (payload) {
                this.items = []
                this.pages = 0
                this.per_page = 0
                this.$emit('afterClear')
              }
            }
          }
      )
    }
  },
  created() {
    this.watch_settings()
    this.watch_generate()
    this.watch_clear()
    this.watch_generate_email()
    this.fetchServices()
    this.watch_generate_xlsx()
    this.watch_generate_send_by_one_email()

    if (this.without_flat) {
      reportAPI.report_settings("receipt_detail")
          .then(response => response.data)
          .then(data => {
            const server_filters = JSON.parse(data)
            Object.keys(this.filters).forEach(key => {
              if (server_filters[key] !== undefined) {
                this.filters[key] = server_filters[key]
              }
            })
            if (server_filters['grouping'] && this.grouping) {
              this.grouping.forEach(g => {
                g.on = !!server_filters.grouping.includes(g.value);
              })
            }
          })
    }
  },
  beforeDestroy() {
    if (this.settings_watcher) {
      this.settings_watcher()
    }
    if (this.generate_watcher) {
      this.generate_watcher()
    }
    if (this.clear_watcher) {
      this.clear_watcher()
    }
    if (this.generate_xlsx_watcher) {
      this.generate_xlsx_watcher()
    }
    if (this.generate_email_watcher) {
      this.generate_email_watcher()
    }
    if (this.generate_send_by_one_email_watcher) {
      this.generate_send_by_one_email_watcher()
    }
  }
}
</script>

<style scoped lang="scss">
$border-color: #cdcdcd;

@media print {
  .page {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }
  .report-table-group-header {
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid;
  }
  .report-table-group-content {
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid;
  }
  //@page {
  //  size: A4 portrait;
  //}

  .page-separator {
    page-break-after: always;
    page-break-inside: avoid;
    page-break-before: avoid;
  }
  //.separator {
  //  page-break-before: right;
  //  //break-before: page;
  //}
}

.page {
  .receipt {
    &:nth-child(1) {
      border-right: 1px dotted $border-color;
      border-bottom: 1px dotted $border-color;
    }

    &:nth-child(2) {
      border-bottom: 1px dotted $border-color;
    }

    &:nth-child(3) {
      border-right: 1px dotted $border-color;
      border-bottom: 1px dotted $border-color;
    }

    &:nth-child(4) {
      border-bottom: 1px dotted $border-color;
    }

    &:nth-child(5) {
      border-right: 1px dotted $border-color;
    }
  }
}

.page {
  .report-section {
    .receipt {
      border: none !important;
      &:first-child {
        margin-top: 0;
      }

      margin-top: 12px;
    }
  }
}

.page-separator {
  page-break-after: always;
  page-break-inside: avoid;
  page-break-before: avoid;
}

.receipt {
  //height: 95mm;
  border: none !important;

  //&:nth-child(even) {
  //  border-left: 1px dotted grey;
  //  border-bottom: 1px dotted grey;
  //}

  .receipt-row {
    display: flex;
  }
}

.receipt-content {
  overflow: hidden;
}

.report-table {
  width: 100%;
  text-align: center;

  .report-table-row:nth-child(1) {
    & > div {
      background-color: rgb(249 249 249 / 74%) !important;
      border-top: 1px solid $border-color;
      border-right: 1px solid $border-color;
      font-weight: 500;

      &:nth-child(1) {
        border-left: 1px solid $border-color;
      }
    }
  }

  .report-table-row:last-child {
    & > div {
      border-bottom: 1px solid $border-color;

      //&:nth-child(1) {
      //  border-left: 1px solid grey;
      //}
    }
  }

  .report-table-row:not(.union-rows) {
    min-height: 16px;
    line-height: 1.12;
    font-size: 11px;

    & > .report-table-col {
      border-top: 1px solid $border-color;
      border-right: 1px solid $border-color;

      &:nth-child(1) {
        border-left: 1px solid $border-color;
      }
    }
  }

  .report-table-row.union-rows {
    min-height: 16px;
    line-height: 1.12;
    font-size: 11px;

    .report-table-col-1 {
      span {
        padding: 2px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .left-col {
      flex: 0 0 169px;
      border-left: 1px solid $border-color;
      border-top: 1px solid $border-color;
      display: flex;
      align-items: center;

      span {
        display: block;
        width: 168px;
        line-height: 15px;
        white-space: break-spaces;
        text-align: left;
        overflow: hidden;
        padding: 2px;
        max-height: 32px;
      }
    }
    .right-col {
      flex: 1;
      border-left: 1px solid $border-color;
      border-right: 1px solid $border-color;
      border-top: 1px solid $border-color;

      .first-row {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        .report-table-col-1 {
          border-left: 1px solid $border-color;

          &:nth-child(1) {
            border-left: none;
          }
        }
      }
      .second-row {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        .report-table-col-1 {
          border-left: 1px solid $border-color;
          border-top: 1px solid $border-color;
          text-align: left;

          span {
            text-align: left;
            justify-content: flex-start;
            font-weight: 500;
          }

          &:nth-child(1) {
            border-left: none;
          }
        }
      }
    }
  }



}

.report-table-col {
  &:nth-child(1) {
    span {
      line-height: 0;
      justify-content: flex-start;
      white-space: nowrap;
      text-align: left;
      width: 168px;
      overflow: hidden;
    }
  }

  span {
    padding: 2px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.report-table-group {
  font-size: 12px;
  width: 100%;

  &:last-child {
    border-bottom: 1px solid $border-color !important;
  }

  .report-table-group-header {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    font-size: 12px;
    border-top: 1px solid $border-color;
    font-weight: 500;
    background-color: #f2f2f2;

    .report-table-col {
      border-right: 1px solid $border-color;

      &:nth-child(1) {
        border-left: 1px solid $border-color;
      }
    }

  }

  .report-table-group-content {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    font-size: 12px;

    .report-table-col {
      border-right: 1px solid $border-color;
      border-top: 1px solid $border-color;

      &:nth-child(1) {
        padding-left: 30px !important;
        padding-right: 5px;
        border-left: 1px solid $border-color;
      }
    }
  }
}

.col-group {
  display: flex;
  flex-direction: column;
  width: 100%;

  .col-group-row {
    display: flex;

    &:nth-child(1) {
      border-bottom: 1px solid $border-color;
      text-align: center;
      justify-content: center;
    }

    .report-table-col {
      border-right: 1px solid $border-color;

      &:last-child {
        border-right: none;
      }
    }
  }
}

</style>